import { RegisterCard } from "../../components/RegisterCard";
import { AiTwotoneCamera, AiFillVideoCamera } from "react-icons/ai";
import "../../assets/css/activities.css";
import { MediaUploadInput } from "../../components/MediaUploadInput";
import { CircleLoader } from "../../components/CircleLoader";

export const CAattitude = ({
  formData,
  onFormChange,
  onFormSubmit,
  loading,
}: any) => {
  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Mood"
          details="Send notification to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between h-[90vh] sm:h-[70vh] px-2.5 w-full">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-32 md:mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Mood
                </h4>
                <div className="w-2/3 md:pl-6">
                  <select
                    required
                    defaultValue={formData["mood"]}
                    onChange={(e) => onFormChange("mood", e.target.value)}
                    name=""
                    id=""
                    className="p-2 w-80 rounded-lg border focus:outline-none"
                  >
                    <option value="">Select Mood</option>
                    <option value="happy">Happy </option>
                    <option value="indifferent">Indifferent</option>
                    <option value="sad">Sad</option>
                    <option value="cranky">Cranky</option>
                    <option value="excited">Excited</option>
                    <option value="gloomy">Gloomy</option>
                    <option value="good">Good</option>
                  </select>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Morale
                </h4>
                <div className="w-2/3 md:pl-6 ">
                  <div className="w-max flex items-center justify-evenly rounded border bg-[#f5f5f5] p-4 py-3">
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        name="morale"
                        checked={formData["morale"] === "high"}
                        onChange={(e) => onFormChange("morale", "high")}
                        type="checkbox"
                        className="form-input checkbox mr-2"
                      />
                      High
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        name="morale"
                        checked={formData["morale"] === "little"}
                        onChange={(e) => onFormChange("morale", "little")}
                        type="checkbox"
                        className="form-input checkbox mr-2"
                      />
                      Little
                    </label>
                    <label className="flex items-center mr-4 text-[14px]">
                      <input
                        name="morale"
                        checked={formData["morale"] === "low"}
                        onChange={(e) => onFormChange("morale", "low")}
                        type="checkbox"
                        className="form-input checkbox mr-2"
                      />
                      Low
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Photo or Video
                </h4>
                <MediaUploadInput
                  singleOption={false}
                  onChangeFn={(e: any, type: any) =>
                    onFormChange(
                      type === "video" ? "video" : "image",
                      e && type === "video"
                        ? e.target.files[0]
                        : e && type === "image"
                        ? e.target.files
                        : ""
                    )
                  }
                  className="w-2/3 md:pl-6 flex flex-col items-center"
                  pdfNull={true}
                />
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">
                  Description
                </h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea
                    defaultValue={formData["description"]}
                    onChange={(e) =>
                      onFormChange("description", e.target.value)
                    }
                    className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"
                  ></textarea>
                </div>
              </div>

              <div className="mt-10 flex h-12 w-full">
                <div className="hidden md:block md:w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button
                    type="submit"
                    className="flex items-center justify-center text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]"
                  >
                    {loading ? <CircleLoader /> : "Send"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img src="/img/attitude.svg" alt="login" className="w-40 h-40" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
