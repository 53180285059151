import { RegisterCard } from "../../components/RegisterCard";
import "../../assets/css/activities.css";
import { TimeSelect } from "../../components/TimeSelect";
import { MediaUploadInput } from "../../components/MediaUploadInput";
import { useEffect } from "react";
import { CircleLoader } from "../../components/CircleLoader";


export const CAsleep = ({formData, onFormChange, onFormSubmit, loading}:any) => {
  useEffect(() =>{
    // onFormChange("start_time", "09:00");
    // onFormChange("end_time", "10:00");
  }, []);

  return (
    <div className="w-full">
      <div className="w-full">
        <RegisterCard
          title="Activities"
          details="Send notification to parent/ guardians"
        />
        <br />
        <form onSubmit={onFormSubmit}>
          <div className="flex  flew-wrap items-center justify-between
           h-[90vh] sm:h-[70vh] px-2.5 my-4 overflow-y-auto overflow-x-hidden w-full ">
            <div className="!p-0 md:w-2/3">
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Start Time</h4>
                <div className="w-full md:w-2/3 md:pl-6 flex items-center">
                  <div className="w-80 min-w-max h-10 mr-1 pb-1 rounded-lg"><TimeSelect value={formData.start_time} onChange={(val:string)=> onFormChange("start_time", val)}  /></div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Stop Time</h4>
                <div className="w-full md:w-2/3 md:pl-6 flex items-center">
                  <div className="w-80 min-w-max h-10 mr-1 pb-1 rounded-lg"><TimeSelect value={formData.end_time} onChange={(val:string)=> onFormChange("end_time", val)}  /></div>
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Photo or Video</h4>
                <MediaUploadInput
                singleOption={false}
                onChangeFn={(e:any, type:any)=> onFormChange(type==="video"?"video" : "image", e && type==="video"? e.target.files[0] : e && type==="image"? e.target.files : "")} className="w-2/3 md:pl-6 flex flex-col items-center"
                pdfNull={true}
                />
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center mt-6">
                <h4 className="text-[14px] font-semibold text-[#012063] opacity-80 text-left md:text-right w-1/3">Description</h4>
                <div className="w-full md:w-2/3 md:pl-6">
                  <textarea name="description"
                  value={formData["description"]}
                  onChange={(e)=> onFormChange("description", e.target.value)}
                  className="w-full border-2 p-4 focus:outline focus:outline-[#012063]"></textarea>
                </div>
              </div>

              <div className="mt-10 flex h-10 w-full">
                <div className="hidden md:block w-1/3"></div>
                <div className="w-full md:w-2/3 md:pl-6">
                  <button className="flex items-center justify-center text-[15px] text-white w-full md:w-[200px] h-full font-bold rounded bg-gradient-to-b from-[#0096D1] to-[#012063]">
                     {loading ? <CircleLoader /> : "Send"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center ml-6 w-[200px] md:w-1/3 reMobile">
              <img
                src="/img/sleeping.svg"
                alt="login"
                className="w-40 h-40"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
